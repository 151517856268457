//
// dropdown.js
//

(function() {
  //
  // Variables
  //

  // Selectors
  const drops = document.querySelectorAll(
    '.navbar-nav .dropdown, .navbar-nav .dropright',
  );

  // Events
  const showEvents = ['mouseenter'];
  const hideEvents = ['mouseleave', 'click'];

  // Transition
  const transitionDuration = 200;

  // Breakpoint
  const desktopSize = 992;

  //
  // Functions
  //

  // Show drop
  function showDrop(menu) {
    if (window.innerWidth < desktopSize) {
      return;
    }

    menu.classList.add('showing');

    setTimeout(() => {
      menu.classList.remove('showing');
      menu.classList.add('show');
    }, 1);
  }

  // Hide drop
  function hideDrop(e, menu) {
    if (window.innerWidth < desktopSize) {
      return;
    }

    if (!menu.classList.contains('show')) {
      return;
    }

    if (e.type === 'click' && e.target.closest('.dropdown-menu form')) {
      return;
    }

    menu.classList.add('showing');
    menu.classList.remove('show');

    setTimeout(() => {
      menu.classList.remove('showing');
    }, transitionDuration);
  }

  //
  // Listeners
  //

  [].forEach.call(drops, (dropdown) => {
    const menu = dropdown.querySelector('.dropdown-menu');

    // Show drop
    showEvents.forEach((event) => {
      dropdown.addEventListener(event, () => {
        showDrop(menu);
      });
    });

    // Hide drop
    hideEvents.forEach((event) => {
      dropdown.addEventListener(event, (e) => {
        hideDrop(e, menu);
      });
    });
  });
})();
