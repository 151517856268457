//
// polyfills.js
// Theme module
//

// SVG
// Polyfill to solve shortcomings of SVG scaling in IE. Inspired by http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/.

(function() {
  //
  // Variables
  //

  const svg = document.querySelectorAll('.svg-shim > svg');

  //
  // Methods
  //

  function init(elem) {
    // Get element's fill value
    const color = window.getComputedStyle(elem, null).getPropertyValue('color');

    // Get SVG's outerHTML and prepare to use it in image
    let content = new XMLSerializer().serializeToString(elem);
    content = content.replace(/currentColor/g, color);
    content = content.replace(/\s\s+/g, ' ');
    content = content.replace(/</g, '%3C');
    content = content.replace(/>/g, '%3E');
    content = content.replace(/#/g, '%23');
    content = content.replace(/"/g, "'");
    content = `data:image/svg+xml,${content}`;

    // Create a replacer image
    const img = document.createElement('img');
    img.src = content;
    img.alt = '...';

    // Replace SVG with image
    const parent = elem.parentNode;
    parent.appendChild(img);
    parent.removeChild(elem);
  }

  //
  // Events
  //

  // Only has affect in IE
  if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
    [].forEach.call(svg, (elem) => {
      init(elem);
    });
  }
})();
