import 'popper.js';
import 'bootstrap';
import jQuery from 'jquery';

import SmoothScroll from 'smooth-scroll';

SmoothScroll('a[href*="#"]');
jQuery(($) => {
  $('[data-toggle="tooltip"]').tooltip();
});
