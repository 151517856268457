//
// choices.js
//
window.Choices = require('choices.js');

(function() {
  const choices = document.querySelectorAll('[data-choices]');

  function init(choice) {
    const elementOptions = choice.dataset.choices
      ? JSON.parse(choice.dataset.choices)
      : {};

    const defaultOptions = {
      searchEnabled: false,
      shouldSort: false,
      classNames: {
        containerInner: choice.className,
        list: 'none',
        listSingle: 'none',
        listDropdown: 'dropdown-menu',
        itemChoice: 'dropdown-item',
        activeState: 'show',
        selectedState: 'active',
      },
    };

    const options = Object.assign(defaultOptions, elementOptions);

    new Choices(choice, options);
  }

  if (typeof window.Choices !== 'undefined' && choices) {
    [].forEach.call(choices, (choice) => {
      init(choice);
    });
  }
})();
